/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { useApolloClient } from '@apollo/client';
import {
  useGroupQuery,
  USERS_QUERY,
  GROUPS_V2_QUERY,
  SEARCH_CUSTOMERS_QUERY,
  AVAILABLE_PERMISSIONS,
  checkIfCurrentUserHasPermission,
} from 'client-lib';
import styled from 'styled-components';
import i18n from 'i18n-js';
import theme from 'styled-theming';
import type {
  CreateCustomer,
  AccountRep,
  CreateCustomerGroup,
} from '../../utils/helpers/types';
import Tooltip from '../../elements/Tooltip/Tooltip';
import {
  Heading2,
  Text,
  EmphasisText,
  Button,
  TextInput,
  Switch,
  Checkbox,
  InfiniteSuggest,
  SelectedEntity,
  Select,
} from '../../elements';
import THEMES from '../../styles/themes/app';
import {
  copyKey,
  findAddedObject,
  type UnknownObject,
} from '../../utils/helpers/objectUtils';
import LabelFormInput from '../LabelFormInput/LabelFormInput';

const ROW_HEIGHT = theme('fontSize', {
  default: '95px',
  large: '128px',
});

const GROUP_ERROR_CONTAINER_MIN_HEIGHT = theme('fontSize', {
  default: '118px',
  large: '132px',
});

const GROUP_ERROR_POSITION = theme('fontSize', {
  default: '70px',
  large: '80px',
});

type Company = {
  value?: string;
  name: string;
  text?: string;
  addCompanySuggestion: boolean;
};

interface Fields {
  id: {
    value: string;
  };
  firstName: {
    value: string;
    ref: HTMLDivElement;
    error: string;
    visited: boolean;
  };
  lastName: {
    value: string;
    visited: boolean;
    ref: HTMLDivElement;
    error: string;
  };
  jobTitle: {
    value: string;
    ref: HTMLDivElement;
    error: string;
  };
  email: {
    value: string;
    visited: boolean;
    ref: HTMLDivElement;
    error: string;
  };
  phoneNumber: {
    value: string;
    error: string;
    ref: HTMLDivElement;
    visited: boolean;
    otherProps: {
      text: string;
    };
  };
  faxNumber: {
    value: string;
    visited: boolean;
    ref: HTMLDivElement;
    error: string;
    otherProps: {
      text: string;
    };
  };
  welcomMessage: {
    value: string;
  };
  group: {
    error: string;
    value: CreateCustomerGroup[];
    otherProps: {
      text: string;
    };
  };
  company: {
    value: string;
    otherProps: {
      text: string;
      name: string;
      addCompanySuggestion: boolean;
    };
    error: string;
    setError: (err: string) => void;
  };
  accountEmail: {
    value: string;
    ref: HTMLDivElement;
    error: string;
    visited: boolean;
  };
  accountNumber: {
    value: string;
    ref: HTMLDivElement;
    error: string;
    visited: boolean;
  };
  accountPhoneNumber: {
    value: string;
    ref: HTMLDivElement;
    error: string;
    visited: boolean;
    otherProps: {
      text: string;
    };
  };
  accountRep: {
    value: string;
    setValue: (val: string) => void;
    otherProps: {
      id: string;
      lastSelectedName: string;
      selectedUser: Record<string, unknown>;
    };
  };
  prioritizeRep: {
    value: boolean;
    setValue: (val: boolean) => void;
  };
  updateContact: {
    value: boolean;
  };
  welcomeMessage: {
    value: boolean;
  };
  updateContactType: {
    value: string;
  };
  isMergeContact: {
    value: boolean;
  };
  mergeOn: {
    value: string;
  };
  newEmail: {
    value: string;
  };
  newFaxNumber: {
    value: string;
    otherProps: {
      text: string;
    };
  };
  phoneSelectValue: {
    value: string;
  };
  newPhoneNumber: {
    value: string;
    otherProps: {
      text: string;
    };
  };
  labels: {
    value: {
      label: string;
      text: string;
      value: string;
    }[];
    text: string;
    color: string;
  };
}

interface UpdateFieldParams<TValue, TOtherProps> {
  value: TValue;
  name: string;
  otherProps?: TOtherProps;
}

type OnChange = {
  value: string;
  label: string;
};

type OnSelect = {
  name: string;
  id: string;
};

interface GroupWelcomeMessageContentProps {
  group: CreateCustomerGroup;
  firstName: string;
  lastName: string;
  accountName: string;
}

interface ExtraProps extends React.ComponentProps<typeof Grid> {
  name?: string;
}

const Container = styled(Grid)`
  width: 100%;
  padding-top: 50px;
  display: flex;
  justify-content: center;
`;

const FlexContainer = styled(Grid)`
  width: 600px;
  max-width: 600px;
`;

const FormContainer = styled(Grid)`
  width: 100%;
`;

const InputWrapperRight = styled(Grid)<ExtraProps>`
  flex: 1;
  margin-left: 5px;
`;

const PriorityNotificationContainer = styled(Grid)`
  display: flex;
  align-items: center;
  flex: 1;
  margin-left: 8px;
`;

const InputRow = styled(Grid)<ExtraProps>`
  display: flex;
  justify-content: space-between;

  &:first-of-type {
    /* This allows enough room for the errors in the first and lastnames */
    height: ${ROW_HEIGHT};
  }
`;

const InputWrapper = styled(Grid)<ExtraProps>`
  flex: 1;
  margin-right: ${(props) => (props.marginRight ? '0' : '5px')};
`;

const WelcomeMessageInput = styled(Grid)<ExtraProps>`
  height: 30px;
  margin-bottom: 8px;
  flex: 1;
`;

const InfoWrapper = styled(Grid)`
  margin-left: 8px;
  font-size: 20px;
  color: ${THEMES.FOREGROUND_LOW};
  display: flex;
  align-items: flex-end;
`;

const SingleInputRow = styled(Grid)`
  flex: 1;
`;

const AccountInput = styled(Grid)<ExtraProps>`
  flex: 1;
`;

const TooltipContainer = styled.div`
  max-width: 400px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const PriorityLabelContainer = styled(Grid)`
  margin-bottom: 7px;
  flex: 1;
`;

const WrapGroupDropDown = styled.div`
  padding-bottom: 40px;
  position: relative;
  min-height: ${GROUP_ERROR_CONTAINER_MIN_HEIGHT};

  input + div {
    position: absolute;
    top: ${GROUP_ERROR_POSITION};
  }

  div[open] {
    top: 0 !important;
  }
`;

const BottomPad = styled.div`
  height: 130px;
`;

const FIRST: number = 10;

const GroupWelcomeMessageContent = ({
  group,
  firstName,
  lastName,
  accountName,
}: GroupWelcomeMessageContentProps) => {
  let welcomeMessageTemplate = '';

  const client = useApolloClient();

  const { data } = useGroupQuery({
    client,
    fetchPolicy: 'network-only',
    variables: { id: group.value ?? group?.id },
  });

  const customStyle = () => 'text-overflow: ellipsis; overflow: hidden;';

  if (data && data.group && data.group.welcomeMessageTemplate) {
    welcomeMessageTemplate = data.group.welcomeMessageTemplate;
  }

  const groupName = group?.text || group?.label || group?.name || '';

  const welcomeMessageText = welcomeMessageTemplate
    .replace('{contact_first_name}', firstName)
    .replace('{contact_last_name}', lastName)
    .replace('{account_name}', accountName)
    .replace('{group_name}', groupName);

  return (
    <>
      <EmphasisText
        customStyle={customStyle}
      >{`From ${groupName}:`}</EmphasisText>
      <Text customStyle={customStyle}>{welcomeMessageText}</Text>
    </>
  );
};

interface TooltipContentProps {
  groups: CreateCustomerGroup[];
  firstName: string;
  lastName: string;
  accountName: string;
}
/* eslint-disable react/prop-types */
const genTooltipContent = ({
  firstName,
  lastName,
  accountName,
  groups,
}: TooltipContentProps) => {
  if (!groups || !groups.length) return null;

  return (
    <>
      {groups.map((group) => (
        <TooltipContainer key={group.value}>
          <GroupWelcomeMessageContent
            group={group}
            firstName={firstName}
            lastName={lastName}
            accountName={accountName}
          />
        </TooltipContainer>
      ))}
    </>
  );
};
/* eslint-enable react/prop-types */

type CustomerFormProps = {
  updateField: <TValue, TOtherProps>(
    args: UpdateFieldParams<TValue, TOtherProps>
  ) => void;
  onSubmit: () => void;
  isVisitedForm: boolean;
  handleOnCompanySelect: (company: Company) => void;
  handleAccountRepSelect: (accountRep: AccountRep) => void;
  handleAccountRepDelete: () => void;
  handleAccountRepBlur: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  handlePhoneNumberChange: (
    ev: React.ChangeEvent<HTMLInputElement>,
    fieldNumberType: string
  ) => void;
  handleFaxNumberChange: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  handleEmailChange: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  handleOnSelectGroup: (groupSelected: CreateCustomerGroup) => void;
  handleOnBlurGroup: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  handleOnDeleteGroup: (groupToBeDeleted: string) => void;
  handleCompanyChange: (value: { target: { value: string } }) => void;
  fields: Fields;
  existingPhoneNumberId: string;
  existingFaxNumberId: string;
  existingEmailId: string;
  accountName: string;
  accountRepEditPermission: boolean;
  priorityNotificationsTogglePermission: boolean;
  createMutationLoading: boolean;
};

interface CreateCustomerFormProps {
  dispatchSetCreateCustomer: (value: CreateCustomer) => void;
  customerFormProps: CustomerFormProps;
  handleOnClose: (customerId: string, forceRoute: boolean) => void;
  accountPolicies: string[];
  groupPolicies: string[];
}

const CreateCustomerForm = ({
  customerFormProps,
  handleOnClose,
  dispatchSetCreateCustomer,
  accountPolicies,
  groupPolicies,
}: CreateCustomerFormProps) => {
  const [firstNameBlur, setFirstNameBlur] = useState(false);
  const [lastNameBlur, setLastNameBlur] = useState(false);

  const {
    updateField,
    onSubmit,
    isVisitedForm,
    handleOnCompanySelect,
    handleAccountRepSelect,
    handleAccountRepDelete,
    handleAccountRepBlur,
    handlePhoneNumberChange,
    handleFaxNumberChange,
    handleEmailChange,
    handleOnSelectGroup,
    handleOnBlurGroup,
    handleOnDeleteGroup,
    handleCompanyChange,
    fields,
    existingPhoneNumberId,
    existingFaxNumberId,
    existingEmailId,
    accountName,
    accountRepEditPermission,
    priorityNotificationsTogglePermission,
    createMutationLoading,
  } = customerFormProps;

  const {
    firstName,
    lastName,
    jobTitle,
    email,
    phoneNumber,
    faxNumber,
    welcomeMessage,
    group,
    company,
    accountEmail,
    accountNumber,
    accountPhoneNumber,
    accountRep,
    prioritizeRep,
    updateContact,
    updateContactType,
    isMergeContact,
    mergeOn,
    newEmail,
    newFaxNumber,
    newPhoneNumber,
    labels,
  } = fields;

  const phoneOptions = () => {
    if (mergeOn.value === 'phoneNumber' && phoneNumber?.value) {
      return [
        {
          label: newPhoneNumber?.otherProps?.text,
          value: newPhoneNumber?.value,
        },
        {
          label: i18n.t('slideouts-CreateCustomerForm-oldData', {
            mergeData: phoneNumber.otherProps.text,
            defaultValue: `${phoneNumber.otherProps.text} (old)`,
          }),
          value: phoneNumber.value,
        },
      ];
    }
    if (mergeOn.value === 'phoneNumber' && !phoneNumber?.value) {
      return [
        {
          label: newPhoneNumber?.otherProps?.text,
          value: newPhoneNumber?.value,
        },
      ];
    }
    return [];
  };

  const faxOptions = () => {
    if (mergeOn.value === 'faxNumber' && faxNumber?.value) {
      return [
        {
          label: newFaxNumber.otherProps.text,
          value: newFaxNumber.value,
        },
        {
          label: i18n.t('slideouts-CreateCustomerForm-oldData', {
            mergeData: faxNumber.otherProps.text,
            defaultValue: `${faxNumber.otherProps.text} (old)`,
          }),
          value: faxNumber.value,
        },
      ];
    }
    if (mergeOn.value === 'faxNumber' && !faxNumber?.value) {
      return [
        {
          label: newFaxNumber.otherProps.text,
          value: newFaxNumber.value,
        },
      ];
    }
    return [];
  };

  const emailOptions = () => {
    if (mergeOn.value === 'emailAddress' && email?.value) {
      return [
        {
          label: newEmail.value,
          value: newEmail.value,
        },
        {
          label: i18n.t('slideouts-CreateCustomerForm-oldData', {
            mergeData: email.value,
            defaultValue: `${email.value} (old)`,
          }),
          value: email.value,
        },
      ];
    }
    if (mergeOn.value === 'emailAddress' && !email?.value) {
      return [
        {
          label: newEmail.value,
          value: newEmail.value,
        },
      ];
    }
    return [];
  };

  const [phoneSelectValue, setPhoneSelectValue] = useState(
    phoneOptions()?.[0]?.value
  );
  const [faxSelectValue, setFaxSelectValue] = useState(
    faxOptions()?.[0]?.value
  );
  const [emailSelectValue, setEmailSelectValue] = useState(
    emailOptions()?.[0]?.value
  );

  useEffect(() => {
    const createCustomerRedux: CreateCustomer = {
      id: fields.id.value,
      firstName: fields.firstName.value,
      lastName: fields.lastName.value,
      jobTitle: fields.jobTitle.value,
      email: fields.email.value,
      phoneNumber: fields.phoneNumber.value,
      faxNumber: fields.faxNumber.value,
      welcomeMessage: fields.welcomeMessage.value,
      company: {
        value: fields.company.value,
        text: fields.company.otherProps.text,
        addCompanySuggestion: fields.company.otherProps.addCompanySuggestion,
      },
      accountPhoneNumber: fields?.accountPhoneNumber
        ? fields?.accountPhoneNumber?.value
        : '',
      accountEmail: fields?.accountEmail ? fields?.accountEmail?.value : '',
      accountNumber: fields?.accountNumber ? fields?.accountNumber?.value : '',
      group: {
        value: fields.group.value,
        text: fields.group.otherProps.text,
      },
      accountRep: {
        value: fields.accountRep.value,
        id: fields.accountRep.otherProps.id,
        lastSelectedName: fields.accountRep.otherProps.lastSelectedName,
        selectedUser: fields.accountRep.otherProps.selectedUser,
      },
      prioritizeRep: fields.prioritizeRep.value,
      updateContact: fields?.updateContact?.value,
      updateContactType: fields?.updateContactType?.value,
      isMergeContact: fields?.isMergeContact?.value,
      mergeOn: fields?.mergeOn?.value,
      newPhoneNumber: fields?.newPhoneNumber.value,
      newFaxNumber: fields?.newFaxNumber.value,
      newEmail: fields?.newEmail.value,
      phoneSelectValue: fields?.phoneSelectValue.value,
    };
    dispatchSetCreateCustomer(createCustomerRedux);
  }, [
    fields.firstName.value,
    fields.lastName.value,
    fields.jobTitle.value,
    fields.email.value,
    fields.phoneNumber.value,
    fields.faxNumber.value,
    fields.welcomeMessage.value,
    fields.company.value,
    fields?.accountPhoneNumber?.value,
    fields?.accountEmail?.value,
    fields?.accountNumber?.value,
    fields.group.value,
    fields.accountRep.value,
    fields.prioritizeRep.value,
    fields.id.value,
    fields?.updateContact?.value,
    fields?.updateContactType?.value,
    fields?.isMergeContact?.value,
    fields?.mergeOn?.value,
    fields?.newPhoneNumber.value,
    fields?.newFaxNumber.value,
    fields?.newEmail.value,
    fields?.phoneSelectValue.value,
  ]);

  const phoneNumberError =
    phoneNumber.visited && existingPhoneNumberId
      ? i18n.t('slideouts-CreateCustomerForm-numAlreadyExists')
      : phoneNumber.error;

  const faxError =
    faxNumber.visited && existingFaxNumberId
      ? i18n.t('slideouts-CreateCustomerForm-numAlreadyExists')
      : faxNumber.error;

  const emailError =
    email.visited && existingEmailId
      ? i18n.t('settings-ProfileFormContainer-emailDuplicateError')
      : email.error;

  const firstNameError =
    firstName.visited && firstNameBlur ? firstName.error : '';

  const lastNameError = lastName.visited && lastNameBlur ? lastName.error : '';

  const handleFirstNameChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = ev.target;
    updateField({ name: 'firstName', value });
  };

  const handleLastNameChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = ev.target;
    updateField({ name: 'lastName', value });
  };

  const handleJobTitleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = ev.target;
    updateField({ name: 'jobTitle', value });
  };

  const handleLabelDropdown = (e: UnknownObject[]) => {
    const newLabels: UnknownObject[] = labels?.value;
    const addedLabel: UnknownObject | null = findAddedObject(
      newLabels,
      e,
      'value'
    );

    updateField({
      name: 'labels',
      value: addedLabel
        ? [...(labels?.value || []), copyKey(addedLabel, 'label', 'text')]
        : e,
    });
  };

  const handleAccountNumberChange = (
    ev: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = ev.target;
    updateField({ name: 'accountNumber', value });
  };

  const handleAccountEmailChange = (
    ev: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = ev.target;
    updateField({ name: 'accountEmail', value });
  };

  const handleWelcomeMessageChange = () => {
    const value = !welcomeMessage.value;
    updateField({ name: 'welcomeMessage', value });
  };

  const handleGroupDownChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = ev.target;
    updateField({
      name: 'group',
      value: group.value,
      otherProps: { text: value },
    });
  };

  const createDisplayCompanySuggestionCallback =
    (companyName: string) => (companySuggestions: Company[]) => {
      // don't let user create customer account with just spaces
      if (companyName?.trim() === '') return false;

      // eslint-disable-next-line no-shadow
      const displaySticky = companySuggestions.reduce((acc, company) => {
        if (!acc) return acc;
        return !(company?.name?.toLowerCase() === companyName?.toLowerCase());
      }, true);
      return displaySticky;
    };

  const userCanEditCompanies = checkIfCurrentUserHasPermission(
    AVAILABLE_PERMISSIONS.EDIT_COMPANIES,
    accountPolicies,
    groupPolicies
  );

  const editContactLabelsPermissions = checkIfCurrentUserHasPermission(
    AVAILABLE_PERMISSIONS.EDIT_CONTACT_LABELS,
    accountPolicies,
    groupPolicies
  );

  useEffect(() => {
    if (company?.otherProps?.name?.length > 100) {
      company.setError(
        i18n.t('contacts-create-companyError', {
          maxLength: 100,
        })
      );
    } else {
      company.setError('');
    }
  }, [company.otherProps.name]);

  return (
    <Container container justifyContent="center">
      <FlexContainer>
        <Grid container alignItems="flex-start">
          <Heading2 dataTestid="new-contact-header">
            {isMergeContact?.value
              ? i18n.t('slideouts-CreateCustomerForm-updateContact', {
                  defaultValue: 'Update Contact',
                })
              : i18n.t('slideouts-CreateCustomerForm-newContact', {
                  defaultValue: 'New Contact',
                })}
          </Heading2>
        </Grid>
        <FormContainer item>
          <InputRow container justifyContent="center">
            <InputWrapper>
              <TextInput
                label={i18n.t('slideouts-CreateCustomerForm-firstName')}
                error={firstNameError}
                dataTestId="first-name-input"
                value={firstName.value}
                onChange={handleFirstNameChange}
                autoFocus
                ref={firstName.ref}
                onBlur={() => setFirstNameBlur(true)}
              />
            </InputWrapper>

            <InputWrapperRight>
              <TextInput
                label={i18n.t('slideouts-CreateCustomerForm-lastName')}
                error={lastNameError}
                dataTestId="last-name-input"
                value={lastName.value}
                onChange={handleLastNameChange}
                ref={lastName.ref}
                onBlur={() => setLastNameBlur(true)}
              />
            </InputWrapperRight>
          </InputRow>

          <InputRow container justifyContent="center">
            <InputWrapper>
              <TextInput
                label={i18n.t('customers-Contacts-title', {
                  defaultValue: 'Job Title',
                })}
                dataTestId="job-title-input"
                value={jobTitle.value}
                onChange={handleJobTitleChange}
                ref={jobTitle.ref}
                error={jobTitle.error}
              />
            </InputWrapper>
          </InputRow>

          <InputRow container justifyContent="center">
            <InputWrapper>
              {isMergeContact && phoneSelectValue ? (
                <Select
                  label={i18n.t('slideouts-CreateCustomerForm-phone')}
                  options={phoneOptions()}
                  value={phoneSelectValue}
                  onChange={(e: OnChange) => {
                    setPhoneSelectValue(e.value);
                    updateField({
                      name: 'phoneSelectValue',
                      value: e.value,
                      otherProps: { text: e.label },
                    });
                  }}
                />
              ) : (
                <>
                  <TextInput
                    label={i18n.t('slideouts-CreateCustomerForm-phone')}
                    error={phoneNumberError}
                    disabled={
                      updateContact.value &&
                      updateContactType.value === 'conversations'
                    }
                    dataTestId="phone-number-input"
                    value={phoneNumber.otherProps.text}
                    onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
                      handlePhoneNumberChange(ev, 'contact')
                    }
                    ref={phoneNumber.ref}
                  />
                  {phoneNumber.visited && existingPhoneNumberId ? (
                    <Button
                      type="link"
                      size="md"
                      noOutline
                      customStyle={() => 'padding: 0;'}
                      onClick={() => handleOnClose(existingPhoneNumberId, true)}
                    >
                      {i18n.t('slideouts-CreateCustomerForm-clickHere')}
                    </Button>
                  ) : null}
                </>
              )}
            </InputWrapper>

            <InputWrapperRight>
              {isMergeContact && faxSelectValue ? (
                <Select
                  label={i18n.t('slideouts-CreateCustomerForm-fax', {
                    defaultValue: 'Fax',
                  })}
                  options={faxOptions()}
                  value={faxSelectValue}
                  onChange={(e: OnChange) => {
                    setFaxSelectValue(e.value);
                    updateField({
                      name: 'faxSelectValue',
                      value: e.value,
                      otherProps: { text: e.label },
                    });
                  }}
                />
              ) : (
                <>
                  <TextInput
                    label={i18n.t('slideouts-CreateCustomerForm-fax', {
                      defaultValue: 'Fax',
                    })}
                    error={faxError}
                    disabled={
                      updateContact.value && updateContactType.value === 'fax'
                    }
                    dataTestId="fax-number-input"
                    value={faxNumber.otherProps.text}
                    onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
                      handleFaxNumberChange(ev)
                    }
                    ref={faxNumber.ref}
                  />
                  {faxNumber.visited && existingFaxNumberId ? (
                    <Button
                      type="link"
                      size="md"
                      noOutline
                      customStyle={() => 'padding: 0;'}
                      onClick={() => handleOnClose(existingFaxNumberId, true)}
                    >
                      {i18n.t('slideouts-CreateCustomerForm-clickHere')}
                    </Button>
                  ) : null}
                </>
              )}
            </InputWrapperRight>
          </InputRow>

          <InputRow container>
            {isMergeContact && emailSelectValue ? (
              <Select
                label={i18n.t('slideouts-CreateCustomerForm-email')}
                options={emailOptions()}
                value={emailSelectValue}
                onChange={(e: OnChange) => {
                  setEmailSelectValue(e.value);
                  updateField({ name: 'emailSelectValue', value: e.value });
                }}
              />
            ) : (
              <>
                <TextInput
                  label={i18n.t('slideouts-CreateCustomerForm-email')}
                  error={emailError}
                  disabled={
                    updateContact.value && updateContactType.value === 'email'
                  }
                  dataTestId="email-input"
                  value={email.value}
                  onChange={handleEmailChange}
                  ref={email.ref}
                />
                {email.visited && existingEmailId ? (
                  <Button
                    type="link"
                    size="md"
                    noOutline
                    customStyle={() => 'padding: 0;'}
                    onClick={() => handleOnClose(existingEmailId, true)}
                  >
                    {i18n.t('slideouts-CreateCustomerForm-clickHere')}
                  </Button>
                ) : null}
              </>
            )}
          </InputRow>

          {accountRepEditPermission && (
            <InputRow container justifyContent="center">
              <InputWrapper
                item
                marginRight={
                  !priorityNotificationsTogglePermission ? '0' : '5px'
                }
              >
                {!accountRep?.otherProps?.selectedUser?.id ? (
                  <InfiniteSuggest
                    inputProps={{
                      label: i18n.t('slideouts-CreateCustomerForm-accountRep'),
                      onBlur: handleAccountRepBlur,
                      value: accountRep.value,
                      onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                        accountRep.setValue(e.target.value),
                      placeholder: i18n.t(
                        'slideouts-CreateThread-enterNameOrNumber'
                      ),
                    }}
                    query={USERS_QUERY}
                    queryKey="users"
                    queryVariables={{
                      after: null,
                      first: FIRST,
                      around: null,
                      before: null,
                      filter: accountRep.value,
                      last: null,
                      groupIds: null,
                    }}
                    onSelect={(
                      e: React.ChangeEvent<HTMLInputElement>,
                      { suggestion }: { suggestion: AccountRep }
                    ) => handleAccountRepSelect(suggestion)}
                  />
                ) : (
                  <SelectedEntity
                    label={i18n.t('slideouts-CreateCustomerForm-accountRep')}
                    maintext={accountRep.otherProps.lastSelectedName}
                    subtext={accountRep.otherProps.selectedUser.role}
                    onDelete={handleAccountRepDelete}
                    avatarProps={{
                      avatarUrl: accountRep.otherProps.selectedUser.avatarUrl,
                      icon: 'user',
                    }}
                  />
                )}
              </InputWrapper>
              {priorityNotificationsTogglePermission && (
                <PriorityNotificationContainer>
                  <PriorityLabelContainer>
                    <Text contrast="med">
                      {i18n.t('slideouts-CreateCustomerForm-priority')}
                    </Text>
                    <Text contrast="low" customStyle={() => 'font-size: 12px;'}>
                      {i18n.t('slideouts-CreateCustomerForm-showNewMessages')}
                    </Text>
                  </PriorityLabelContainer>
                  <Grid>
                    <Switch
                      checked={prioritizeRep.value}
                      onCheck={() =>
                        prioritizeRep.setValue(!prioritizeRep.value)
                      }
                      disabled={!accountRep.otherProps.id}
                    />
                  </Grid>
                </PriorityNotificationContainer>
              )}
            </InputRow>
          )}

          {editContactLabelsPermissions && (
            <InputRow>
              <SingleInputRow>
                <LabelFormInput
                  setLabelValues={handleLabelDropdown}
                  labelValues={labels?.value}
                />
              </SingleInputRow>
            </InputRow>
          )}

          <InputRow container justifyContent="center">
            <SingleInputRow>
              <InfiniteSuggest
                inputProps={{
                  label: i18n.t('slideouts-CreateCustomerForm-company'),
                  onBlur: (a: React.FocusEvent<HTMLInputElement>) => {
                    if (a?.target?.value?.trim() === '') {
                      handleCompanyChange({ target: { value: '' } });
                    }
                  },
                  value: company.otherProps.name,
                  onChange: handleCompanyChange,
                  placeholder: i18n.t(
                    'slideouts-CreateCustomerForm-searchCompanies'
                  ),
                  error: company.error,
                }}
                query={SEARCH_CUSTOMERS_QUERY}
                queryKey="searchCustomers"
                queryVariables={{
                  typeFilter: 'CUSTOMER_ACCOUNT',
                  query: company.otherProps.name,
                  first: FIRST,
                }}
                onSelect={(
                  e: React.ChangeEvent<HTMLInputElement>,
                  { suggestion }: { suggestion: Company }
                ) => handleOnCompanySelect(suggestion)}
                displayStickyButton={() =>
                  userCanEditCompanies &&
                  createDisplayCompanySuggestionCallback(
                    company.otherProps.name
                  )
                }
                stickyButtonText={i18n.t(
                  'slideouts-CompanyDropDown-createNew',
                  { companyName: company.otherProps.name, defaultValue: '' }
                )}
                onStickyButtonClick={() =>
                  handleOnCompanySelect({
                    addCompanySuggestion: true,
                    name: company.otherProps.name,
                  })
                }
              />
            </SingleInputRow>
          </InputRow>

          {company.otherProps.addCompanySuggestion && (
            <>
              <InputRow container justifyContent="center">
                <InputWrapper
                  // item
                  name="companyPhone"
                >
                  <TextInput
                    label={i18n.t('slideouts-CreateCustomerForm-companyPhone')}
                    error={accountPhoneNumber.error}
                    value={accountPhoneNumber.otherProps.text}
                    onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
                      handlePhoneNumberChange(ev, 'account')
                    }
                    ref={accountPhoneNumber.ref}
                  />
                </InputWrapper>
                <InputWrapperRight
                  // item
                  name="companyEmail"
                >
                  <TextInput
                    label={i18n.t('slideouts-CreateCustomerForm-companyEmail')}
                    error={accountEmail.error}
                    value={accountEmail.value}
                    onChange={handleAccountEmailChange}
                    ref={accountEmail.ref}
                  />
                </InputWrapperRight>
              </InputRow>

              <InputRow container>
                <AccountInput item name="accountNumber">
                  <TextInput
                    label={i18n.t('slideouts-CreateCustomerForm-accountNumber')}
                    error={accountNumber.error}
                    value={accountNumber.value}
                    onChange={handleAccountNumberChange}
                    ref={accountNumber.ref}
                  />
                </AccountInput>
              </InputRow>
            </>
          )}

          <InputRow container justifyContent="center">
            <SingleInputRow item>
              <WrapGroupDropDown>
                <InfiniteSuggest
                  inputProps={{
                    label: i18n.t('slideouts-CreateCustomerForm-group'),
                    onBlur: (e: React.ChangeEvent<HTMLInputElement>) =>
                      handleOnBlurGroup(e),
                    value: group.otherProps.text,
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                      handleGroupDownChange(e),
                    placeholder: i18n.t(
                      'settings-GroupDropDown-groupDropDownPlaceholder'
                    ),
                    error: group.error,
                    disabled: !!isMergeContact.value,
                  }}
                  multiSelectedSuggestions={
                    group?.value?.map((groupItem) => ({
                      text: groupItem.text || groupItem.label || '',
                      value: groupItem.value || '',
                    })) || []
                  }
                  onMultiSelectionDelete={(
                    e: React.ChangeEvent<HTMLInputElement>,
                    { suggestion }: { suggestion: OnChange }
                  ) => handleOnDeleteGroup(suggestion.value)}
                  onSelect={(
                    e: React.ChangeEvent<HTMLInputElement>,
                    { suggestion }: { suggestion: OnSelect }
                  ) =>
                    handleOnSelectGroup({
                      text: suggestion.name,
                      value: suggestion.id,
                    })
                  }
                  query={GROUPS_V2_QUERY}
                  queryKey="groupsV2"
                  queryVariables={{
                    limit: FIRST,
                    filter: group.otherProps.text,
                  }}
                  label={i18n.t('settings-TeamMemberForm-groups', {
                    defaultValue: 'Groups',
                  })}
                  placeholder={i18n.t(
                    'settings-GroupDropDown-groupDropDownPlaceholder'
                  )}
                  isMulti
                  omitAll
                  skipPermissionCheck
                />
              </WrapGroupDropDown>
            </SingleInputRow>
          </InputRow>
          <WelcomeMessageInput
            container
            name="welcomeMessage"
            alignItems="center"
          >
            <Checkbox
              disabled={!phoneNumber.value}
              checked={welcomeMessage.value}
              onCheck={handleWelcomeMessageChange}
              label={i18n.t('slideouts-CreateCustomerForm-welcomeMessage')}
            />
            <div style={{ position: 'relative' }}>
              {group.value.length > 0 ? (
                <Tooltip
                  id="welcomeTooltip"
                  place="top"
                  elementsContent={genTooltipContent({
                    firstName: firstName.value,
                    lastName: lastName.value,
                    accountName,
                    groups: group.value,
                  })}
                  customStyle={() => `position: absolute!important; 
                                      top: -140px !important; 
                                      right: auto !important; 
                                      left: -116px !important;
                                      width: 250px;`}
                  dataFor="defaultData"
                  disabled={false}
                >
                  <InfoWrapper>
                    <i
                      data-tip
                      data-for="welcomeTooltip"
                      className="ri-information-line"
                    />
                  </InfoWrapper>
                </Tooltip>
              ) : (
                ''
              )}
            </div>
          </WelcomeMessageInput>
          <Button
            loadingSpinner={createMutationLoading}
            disabled={
              (!isVisitedForm && !isMergeContact.value) || createMutationLoading
            }
            onClick={onSubmit}
            dataTestId="submit-button"
          >
            {i18n.t('slideouts-CreateCustomerForm-submit')}
          </Button>
          <BottomPad />
        </FormContainer>
      </FlexContainer>
    </Container>
  );
};

export default CreateCustomerForm;
